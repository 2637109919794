import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import LinkedIcon from '../../../../assets/images/linkedin.svg'
import FacebookIcon from '../../../../assets/images/facebook.svg'
import TwitterIcon from '../../../../assets/images/twitter.svg'
import InstagramIcon from '../../../../assets/images/insta.svg'
import { formatPhoneNumber } from '../../../../helpers/formatPhoneNumber'
import { GatsbyImage } from 'gatsby-plugin-image'
import LinkAtom from '../../../atoms/links/links'
import isOuterLink from '../../../../helpers/isOuterLink'
import getCalloutLink from '../../../../helpers/getCalloutLink'
import {
  AddressDetails,
  BottomInner,
  BottomNavList,
  BottomNavListItem,
  BottomNavListItemLink,
  BottomWrapper,
  Categories,
  ContactBlock,
  ContactLink,
  FooterNavList,
  FooterNavListItem,
  FooterNavListTitle,
  FooterNavListWrapper,
  FormBlock,
  GlobalFooterInner,
  GlobalFooterWrapper,
  LogoWrapper,
  MiddleBlock,
  MiddleInner,
  MiddleWrapper,
  PublicationDetails,
  SocialLink,
  SocialLinks,
  SocialLinksList,
  SocialLinksListItem,
  SocialsBlock,
} from './NewFooter.styles'
import ContactFormFooter from '../../Forms/ContactFormFooter/ContactFormFooter'

function getFooterData() {
  const data = useStaticQuery(graphql`
    query {
      contentfulComponentConfigData {
        contactsFacebook
        contactsTwitter
        contactsLinkedin
        contactsInstagram
        contactsPhone
        contactsEmail
        contactsAddress
        footerLogo {
          url
          gatsbyImageData
          title
        }
        footerBottomLinks {
          label
          link
        }
        footerFirstCategoryLinkList {
          id
          linkText
          linkDestination
        }
        footerSecondCategoryLinkList {
          id
          linkText
          linkDestination
        }
        footerThirdCategoryLinkList {
          id
          linkText
          linkDestination
        }
        footerFourthCategoryLinkList {
          id
          linkText
          linkDestination
        }
        footerFifthCategoryLinkList {
          id
          linkText
          linkDestination
        }
      }
    }
  `)

  const facebookLink = data?.contentfulComponentConfigData?.contactsFacebook || '#'
  const linkedinLink = data?.contentfulComponentConfigData?.contactsLinkedin || '#'
  const twitter = data?.contentfulComponentConfigData?.contactsTwitter || '#'
  const instagram = data?.contentfulComponentConfigData?.contactsInstagram || '#'
  const phone = data?.contentfulComponentConfigData?.contactsPhone || ''
  const address = data?.contentfulComponentConfigData?.contactsAddress || ''
  const email = data?.contentfulComponentConfigData?.contactsEmail || ''
  const footerBottomLinks = data?.contentfulComponentConfigData?.footerBottomLinks
  const footerLogo = data?.contentfulComponentConfigData?.footerLogo.gatsbyImageData
  const footerLogoTitle = data?.contentfulComponentConfigData?.footerLogo.title
  const footerLinkCategories = [
    {
      categoryTitle: 'Services',
      footerLinkList: data?.contentfulComponentConfigData?.footerFirstCategoryLinkList,
    },
    {
      categoryTitle: 'Legal',
      footerLinkList: data?.contentfulComponentConfigData?.footerSecondCategoryLinkList,
    },
    {
      categoryTitle: 'Industry',
      footerLinkList: data?.contentfulComponentConfigData?.footerThirdCategoryLinkList,
    },
    {
      categoryTitle: 'About us',
      footerLinkList: data?.contentfulComponentConfigData?.footerFourthCategoryLinkList,
    },
    {
      categoryTitle: 'Resources',
      footerLinkList: data?.contentfulComponentConfigData?.footerFifthCategoryLinkList,
    },
  ].filter(({ categoryTitle }) => categoryTitle)

  return {
    facebookLink,
    linkedinLink,
    twitter,
    instagram,
    phone,
    email,
    footerLogo,
    footerLinkCategories,
    footerBottomLinks,
    footerLogoTitle,
    address,
  }
}

const Socials = ({
  isMobile,
  linkedinLink,
  facebookLink,
  twitterLink,
  instagramLink,
  phone,
  email,
}) => {
  return (
    <SocialsBlock isMobile={isMobile}>
      <ContactLink>
        <span>
          <b>Phone us</b>
        </span>
        <a href={`tel:${phone}`}>{formatPhoneNumber(phone)}</a>
      </ContactLink>
      <ContactLink>
        <span>
          <b>Email us</b>
        </span>
        <a href={`mailto:${email}`}>{email}</a>
      </ContactLink>
      <SocialLinks>
        <span>
          <b>Follow us</b>
        </span>
        <SocialLinksList>
          <SocialLinksListItem>
            <SocialLink
              href={linkedinLink}
              icon={LinkedIcon}
              aria-label="linked in"
              target="_blank"
              rel="noopener noreferrer"
            />
          </SocialLinksListItem>
          <SocialLinksListItem>
            <SocialLink
              href={facebookLink}
              icon={FacebookIcon}
              aria-label="facebook"
              target="_blank"
              rel="noopener noreferrer"
            />
          </SocialLinksListItem>
          <SocialLinksListItem>
            <SocialLink
              href={twitterLink}
              icon={TwitterIcon}
              aria-label="twitter"
              target="_blank"
              rel="noopener noreferrer"
            />
          </SocialLinksListItem>
          <SocialLinksListItem>
            <SocialLink
              href={instagramLink}
              icon={InstagramIcon}
              aria-label="instagram"
              target="_blank"
              rel="noopener noreferrer"
            />
          </SocialLinksListItem>
        </SocialLinksList>
      </SocialLinks>
    </SocialsBlock>
  )
}

function NewFooter() {
  const {
    footerLogoTitle,
    footerLinkCategories,
    footerLogo,
    footerBottomLinks,
    email,
    facebookLink,
    linkedinLink,
    twitter,
    instagram,
    phone,
    address,
  } = getFooterData()

  return (
    <GlobalFooterWrapper>
      <GlobalFooterInner>
        <MiddleWrapper>
          <div className="outer">
            <div className="inner">
              <MiddleInner>
                <ContactBlock>
                  <LogoWrapper>
                    <GatsbyImage objectFit="contain" image={footerLogo} alt={footerLogoTitle} />
                  </LogoWrapper>
                  <Socials
                    email={email}
                    facebookLink={facebookLink}
                    instagramLink={instagram}
                    linkedinLink={linkedinLink}
                    phone={phone}
                    twitterLink={twitter}
                  />
                </ContactBlock>
                <MiddleBlock>
                  <FormBlock>
                    <ContactFormFooter />
                  </FormBlock>
                  <Categories>
                    {footerLinkCategories.length > 0 &&
                      footerLinkCategories.map(({ categoryTitle, footerLinkList }) => (
                        <FooterNavListWrapper key={categoryTitle}>
                          <FooterNavList>
                            <FooterNavListTitle>{categoryTitle}</FooterNavListTitle>
                            {footerLinkList &&
                              footerLinkList.map(({ id: linkId, linkText, linkDestination }) => (
                                <FooterNavListItem key={linkId}>
                                  {isOuterLink(getCalloutLink(linkDestination)) ? (
                                    <LinkAtom
                                      linkType="Footer Link"
                                      target={
                                        isOuterLink(getCalloutLink(linkDestination))
                                          ? '_blank'
                                          : '_self'
                                      }
                                      rel={
                                        isOuterLink(getCalloutLink(linkDestination))
                                          ? 'noopener noreferrer'
                                          : ''
                                      }
                                      linkDestination={getCalloutLink(linkDestination)}
                                      linkText={linkText}
                                    />
                                  ) : (
                                    <LinkAtom
                                      linkType="Footer Inner Link"
                                      linkDestination={getCalloutLink(linkDestination)}
                                      linkText={linkText}
                                    />
                                  )}
                                </FooterNavListItem>
                              ))}
                          </FooterNavList>
                        </FooterNavListWrapper>
                      ))}
                    <Socials
                      isMobile
                      email={email}
                      facebookLink={facebookLink}
                      instagramLink={instagram}
                      linkedinLink={linkedinLink}
                      phone={phone}
                      twitterLink={twitter}
                    />
                  </Categories>
                </MiddleBlock>
              </MiddleInner>
            </div>
          </div>
        </MiddleWrapper>
      </GlobalFooterInner>
      <BottomWrapper>
        <div className="outer">
          <div className="inner">
            <BottomInner>
              <AddressDetails>{address}</AddressDetails>
              <PublicationDetails>Copyright © 2025 Colena Ltd / heliguy™</PublicationDetails>
              {footerBottomLinks.length > 0 && (
                <BottomNavList>
                  {footerBottomLinks.map((item) => (
                    <BottomNavListItem key={item.label}>
                      <BottomNavListItemLink to={item.link}>{item.label}</BottomNavListItemLink>
                    </BottomNavListItem>
                  ))}
                </BottomNavList>
              )}
            </BottomInner>
          </div>
        </div>
      </BottomWrapper>
    </GlobalFooterWrapper>
  )
}

export default NewFooter
